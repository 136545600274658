import * as React from 'react'
import styled from 'styled-components'
import { useLink } from '@bestyled/contrib-common'

import { Link } from 'gatsby'
import { useI18n } from '@bestyled/primitives'
import Image from './Image'
import { ISpecialist } from '../types'

const Bio: React.FC<{ specialist: ISpecialist }> = ({ specialist }) => {
  const { getLangPath } = useI18n()
  return (
    <BioContainer>
      <BioAvatar
        to={getLangPath(specialist.slug)}
        data-a11y="false"
        aria-label={specialist.name}
      >
        <BioAvatarInner>
          <RoundedImage src={{ ...specialist.avatar.medium, aspectRatio: 1 }} />
        </BioAvatarInner>
      </BioAvatar>
      <BioText dangerouslySetInnerHTML={{ __html: specialist.title }} />
    </BioContainer>
  )
}

export default Bio

const BioContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  left: -10px;
`

const BioAvatar = styled(Link)`
  display: block;
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  margin-right: 16px;
  margin: 10px 26px 10px 10px;

  &::after {
    content: '';
    position: absolute;
    left: -5px;
    top: -5px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -5px;
    top: -5px;
    width: 50px;
    height: 50px;
    border: 2px solid ${(p) => p.theme.colors.accent};
  }
` as React.FC<any>

const RoundedImage = styled(Image)`
  border-radius: 50%;
`

const BioAvatarInner = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  margin-right: 16px;
  overflow: hidden;
`

const BioText = styled.p`
  max-width: 430px;
  font-size: 14px;
  line-height: 1.45;
  color: ${(p) => p.theme.colors.grey};

  a {
    color: ${(p) => p.theme.colors.grey};
    text-decoration: underline;
  }
`
