/* eslint-disable no-restricted-globals */
import React, { useCallback } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import styled from 'styled-components'

import { useI18n } from '@bestyled/primitives'
import { mediaqueries, Button } from '@slipway/theme-components'

const siteQuery = graphql`
  {
    allPage(
      filter: { menu: { glob: "action*" } }
      sort: { fields: menu, order: ASC }
    ) {
      edges {
        node {
          id
          menu
          menuTitle
          lang
          slug
          title
        }
      }
    }
  }
`

export const NavigationMenuAction: React.FC<{}> = () => {
  const { allPage: menuItems } = useStaticQuery(siteQuery)
  const { isCompatible, locale } = useI18n()

  const navItems = menuItems.edges
    .map((edge) => ({
      id: edge.node.id,
      title: edge.node.menuTitle || edge.node.title,
      href: edge.node.slug,
      lang: edge.node.lang
    }))
    .filter((node) => isCompatible(node.lang))

  return (
    <ActionBar>
      <ActionNavList id="action-list">
        <ActionItems items={navItems} />
      </ActionNavList>
    </ActionBar>
  )
}

export default NavigationMenuAction

const ActionBar = styled.div`
  background: inherit;
  width: 100%;
`

const ActionNavList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: inherit;
  list-style: none;
  justify-content: center;

  ${mediaqueries.phablet`
  flex-direction: column;
  `};
`

const NavItem = styled.li`
  padding-left: 20px;
  padding-right: 20px;

  ${mediaqueries.tablet`
    min-width: 150px;
  `};
`

const ActionItems: React.FC<{
  items: { id: string; title: string; href: string; disabled?: boolean }[]
}> = ({ items }) => {
  return (
    <>
      {[
        ...items.map((nav, index) => {
          return (
            <NavItem key={nav.id}>
              <Button to={nav.href}>{nav.title}</Button>
            </NavItem>
          )
        })
      ]}
    </>
  )
}
