import * as React from 'react'
import styled from 'styled-components'

import { useI18n } from '@bestyled/primitives'
import { Headings, Section, mediaqueries } from '@slipway/theme-components'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Paginator from '../components/Navigation.Paginator'

import ArticlesHero from '../sections/articles/Articles.Hero'
import ArticlesList from '../sections/articles/Articles.List'

import { Template, IArticle } from '../types'

const ArticlesPage: Template = ({ location, pageContext }) => {
  const articlesBySection = (pageContext.group as IArticle[]).reduce(
    (accum, article) => {
      accum[article.section] = accum[article.section] || []
      accum[article.section].push(article)
      return accum
    },
    {}
  )

  const { specialists } = pageContext.additionalContext

  return (
    <Layout
      location={location}
      basePath={pageContext.basePath}
      locale={pageContext.locale}
      languages={pageContext.languages}
      translations={pageContext.translations}
    >
      <SEO pathname={location.pathname} />
      <ArticlesHero specialists={specialists} />
      <Section narrow>
        {Object.keys(articlesBySection).map((section) => (
          <ArticleSection
            key={section}
            section={section}
            articles={articlesBySection[section]}
          />
        ))}
        <ArticlesPaginator show={pageContext.pageCount > 1}>
          <Paginator {...(pageContext as any)} />
        </ArticlesPaginator>
      </Section>
      <ArticlesGradient />
    </Layout>
  )
}

export default ArticlesPage

export const ArticleSection: React.FC<{
  section: number | string
  articles: IArticle[]
}> = ({ section, articles }) => {
  const { t } = useI18n()

  return (
    <>
      <ArticleSectionHeader>
        {t(`Articles.Section.${section}`)}
      </ArticleSectionHeader>
      <ArticlesList articles={articles} />
    </>
  )
}

const ArticleSectionHeader = styled(Headings.h3 as React.FC<{}>)`
  font-family: ${(p) => p.theme.fonts.runningheader};
  font-size: 14px;
  text-transform: ${(p) => p.theme.textTransforms.runningheader};
  color: ${(p) => p.theme.colors.brand};
  margin-bottom: 35px;
  margin-top: 35px;

  ${mediaqueries.desktop`
    margin-bottom: 15px;
  `}

  ${mediaqueries.phablet`
    font-size: 12px;  
    padding: 0 0 0;
    margin-bottom: 10px;
    text-align: center;
  `}
`

const ArticlesGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 590px;
  z-index: 0;
  pointer-events: none;
  background: ${(p) => p.theme.colors.gradient};
  transition: ${(p) => p.theme.colorModeTransition};
`

const ArticlesPaginator = styled.div<{ show: boolean }>`
  ${(p) => p.show && `margin-top: 95px;`}
`
